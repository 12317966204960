<template>
	<div class="container-modal form-modal">
        <div v-if="$resize && $mq.above(601)" class="content-icon forgot" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="112" :width="112" v-on:animCreated="handleAnimationLogo"/>
        </div>
		
		<div class="container-holder">
			<div class="info-content">
				<div class="header-modal">
					<div class="title">{{ $t('register.title_step_4') }}</div>
				</div>
				<div class="container-content-modal">
					<div class="form-section-register">
						<!-- Date facturare -->

						<div class="input-login-group-tbf w-mob-100 w-tbf-50">
							<div class="personal-choice" v-bind:class="{active: selectedTypePerson == 'company'}" @click="changeTypePerson('company')">
								<div class="type">
									<span class="title">{{ $t('subscription.company_title') }}</span>
									<span>{{ $t('subscription.team') }}</span>
								</div>
								<div class="checkboxes-group">
									<label class="checkbox-tbf">
										<input type="radio" value="company" v-model="selectedTypePerson">
										<span class="checkmark"></span>
									</label>					
								</div>
							</div>
						</div>
						<div class="input-login-group-tbf w-mob-100 w-tbf-50">
							<div class="personal-choice" v-bind:class="{active: selectedTypePerson == 'individual'}" @click="changeTypePerson('individual')">
								<div class="type">
									<span class="title">{{ $t('subscription.individual') }}</span>
									<span>{{ $t('subscription.personal') }}</span>
								</div>
								<div class="checkboxes-group">
									<label class="checkbox-tbf">
										<input type="radio" value="individual" v-model="selectedTypePerson">
										<span class="checkmark"></span>
									</label>					
								</div>
							</div>
						</div>

						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.company.complet_name.$error, complete: company.complet_name != '' && !$v.company.complet_name.$error}" v-if="selectedTypePerson == 'individual'">
							<div class="label-input">
								<label>{{ $t('subscription.complet_name') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-people /></div>
								<div class="icon-right" v-if="company.complet_name != ''" @click.stop="company.complet_name = ''"><icon-close /></div>
								<input type="text" :placeholder="$t('subscription.complet_name')" class="input-text" v-model="company.complet_name" ref="first_input">
							</div>
						</div>

						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.company.cnp.$error, complete: company.cnp != '' && !$v.company.cnp.$error }" v-if="selectedTypePerson == 'individual'">
							<div class="label-input">
								<label>{{$t('subscription.cnp')}}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-cnp /></div>
								<div class="icon-right" v-if="company.cnp != ''" @click.stop="company.cnp = ''"><icon-close /></div>
								<input type="text" :placeholder="$t('subscription.cnp')" class="input-text" v-model="company.cnp">
							</div>
						</div>

						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.company.name.$error, complete: company.name != '' && !$v.company.name.$error}" v-if="selectedTypePerson == 'company'">
							<div class="label-input">
								<label>{{$t('subscription.company')}}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-building /></div>
								<div class="icon-right" v-if="company.name != ''" @click.stop="company.name = ''"><icon-close /></div>
								<input type="text" :placeholder="$t('subscription.company')" class="input-text" v-model="company.name">
							</div>
						</div>

						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.company.cui.$error, complete: company.cui != '' && !$v.company.cui.$error}" v-if="selectedTypePerson == 'company'">
							<div class="label-input">
								<label>{{ $t('subscription.cui') }}</label>
								<div class="error-msg" v-if="invalidCui">{{ $t('validator.cui_invalid') }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-cui /></div>
								<div class="icon-right" v-if="company.cui != ''" @click.stop="company.cui = ''"><icon-close /></div>
								<input type="text" :placeholder="$t('subscription.cui')" class="input-text" v-model="company.cui">
							</div>
						</div>
						<!-- end date facturare -->
						<div class="input-login-group-tbf checkboxes-group">
							<label class="checkbox-tbf no-margin">
								<span class="text-checkbox" v-bind:class="{'error-msg-terms': $v.user.terms_and_conditions.$error}" href="https://tbf.ro/termeni-si-conditii-tbf-digital/" v-html="$t('subscription.terms', {terms_link: 'https://tbf.ro/termeni-si-conditii-tbf-digital/'})">
								</span>
								<input type="checkbox" value="1" v-model="user.terms_and_conditions">
								<span class="checkmark"></span>
							</label>						
						</div>
					</div>
					<div class="submit-form">
						<button id="buttonNextStep" class="btn-tbf blue center" @click="goNextStep">
							<div class="loader"></div>
							<div class="text">{{$t('register.next_step')}}</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../../assets/animations/tbfdigital_logo.json';
	import IconClose from '../Icons/Close'
	import IconPeople from "../Icons/User"
	import IconCnp from '../Icons/Cnp'
	import IconBuilding	from '../Icons/Building'
	import IconCui	from '../Icons/Cui'
	import { required, minLength, requiredIf, sameAs } from 'vuelidate/lib/validators'

	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				bird_noti: false,
				optionsCountry: [],
				value_country: '',
				selectedTypePerson: 'company',
				invalidCui: '',
				subModalShow: false,
				company: {
					name: '',
					complet_name: '',
					cui: '',
					cnp: ''
				}
			}
		},
		props:{
			user: Object
		},
		components: {
			IconClose,
			IconPeople,
			IconBuilding,
			IconCnp,
			IconCui,
			Lottie
		},
		validations: {
			selectedTypePerson: {required},
			user: {
				terms_and_conditions: { sameAs: val => val === true },
			},
			company: {
				name: { required: requiredIf(function(){ return this.user.has_company === 1 })},
				cui: { required: requiredIf(function(){ return this.user.has_company === 1 })},
				complet_name: { required: requiredIf(function(){ return this.user.has_company === 0 })},
				cnp: { required: requiredIf(function(){ return this.user.has_company === 0  })}
			}
		},
		async mounted() {
			this.company.complet_name = this.user.last_name + ' ' + this.user.first_name
			this.logoAnim();
		},
		methods: {
			goNextStep(){
				this.invalidCui = false;

				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.$v.$touch()
				if(this.$v.$invalid){
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('register.next_step')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}else{
					if(this.user.has_company){
						this.checkVatNumber()
					}else{
						this.submitRegister()
					}
				}
			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished && !this.bird_noti){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			},
			checkVatNumber(){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				
				axios.post('/check-vat', {cui: `${this.user.country ? this.user.country.iso : 'RO'}${this.company.cui}`})
				.then(({data}) => {
					if(data.is_valid){
						this.submitRegister()
					}else{
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								btnSubmitText.innerHTML = this.$t('register.next_step')
								btnSubmit.disabled = false

								this.invalidCui = true
							}, 1000)
						}, 300)
					}
				})
			},
			submitRegister(){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				var formData = {
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					password: this.user.password,
					email: this.user.email,
					country: this.user.country ? this.user.country.iso : 'RO',
					has_company: this.user.has_company,
					agree_with_terms: this.user.terms_and_conditions,
					package_token: this.user.packageActive.token,
					referral_uuid: this.user.referral_uuid,
					coupon: this.user.coupon
				}
				if(this.user.has_company){
					formData.company_name = this.company.name
					formData.cui = `${this.user.country ? this.user.country.iso : 'RO'}${this.company.cui}`
				}else{
					formData.name = this.company.complet_name
					formData.cnp = this.company.cnp
				}

				var app = this
				this.$auth.register({
					data: formData,
					redirect: {name: 'payment'},
					staySignedIn: true,
					autoLogin: true,
					fetchUser: true
				})
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
						}, 1000)
					}, 300)
				}, (res) => {
					let response = res.response.data
					if(response.status == 'error'){
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								btnSubmitText.innerHTML = this.$t('login.login-button')
							}, 1000)
						}, 300)
					}
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			selectCountry(value, id){
				this.user.country = value
			},
			changeTypePerson(type) {
				this.selectedTypePerson = type;
				if(this.selectedTypePerson == 'company'){
					this.user.has_company = 1;
				}else{
					this.user.has_company = 0;
				}
			}
		}
	}
</script>