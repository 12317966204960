<template>
	<div class="container-modal form-modal" id="step1">
        <div v-if="$resize && $mq.above(601)" class="content-icon forgot" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="112" :width="112" v-on:animCreated="handleAnimationLogo"/>
        </div>
		<div class="container-holder">
			<div class="info-content">
				<div class="header-modal">
					<div class="title">{{ $t('register.title') }}</div>
				</div>
				<div class="container-content-modal">
					<div class="form-section-register">
						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.first_name.$error, complete: user.first_name != '' && !$v.user.first_name.$error}">
							<div class="label-input">
								<label>{{ $t('general.first_name') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-people /></div>
								<div class="icon-right" v-if="user.first_name != ''" @click.stop="user.first_name = ''"><icon-close /></div>
								<input type="text" :placeholder="$t('general.first_name')" class="input-text" v-model="user.first_name" ref="first_input">
							</div>
						</div>

						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.last_name.$error, complete: user.last_name != '' && !$v.user.last_name.$error}">
							<div class="label-input">
								<label>{{ $t('general.last_name') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-people /></div>
								<div class="icon-right" v-if="user.last_name != ''" @click.stop="user.last_name = ''"><icon-close /></div>
								<input type="text" :placeholder="$t('general.last_name')" class="input-text" v-model="user.last_name" ref="second_input">
							</div>
						</div>

						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.email.$error || mail_exists, complete: user.email != '' && !$v.user.email.$error && !mail_exists}">
							<div class="label-input">
								<label>{{$t('general.email')}}</label>
								<div class="error-msg" v-if="mail_exists">{{ $t('validator.email_already_exists') }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-email /></div>
								<div class="icon-right" v-if="user.email != ''" @click.stop="user.email = ''"><icon-close /></div>
								<input type="email" :placeholder="$t('register.email-placeholder')" class="input-text" v-model="user.email" ref="third_input">
							</div>
						</div>

						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.password.$error, complete: user.password != '' && !$v.user.password.$error }">
							<div class="label-input">
								<label>{{$t('register.password')}}</label>
								<div class="error-msg pwd-error-msg" v-if="!$v.user.password.minLength">{{ $t('validator.password_min_length') }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-password /></div>
								<div class="icon-right" v-if="user.password != ''" @click.stop="user.password = ''"><icon-close /></div>
								<input autocomplete="off" type="password" :placeholder="$t('register.password-placeholder')" class="input-text" v-model="user.password">
							</div>
						</div>

						<div class="input-login-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.phone.$error, complete: user.phone != '' && !$v.user.phone.$error}">
							<div class="label-input">
								<label>{{$t('general.phone')}}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-phone /></div>
								<div class="icon-right" v-if="user.phone != ''" @click.stop="user.phone = ''"><icon-close /></div>
								<input type="number" :placeholder="$t('register.phone-placeholder')" class="input-text phone" v-model="user.phone" ref="fourth_input">
							</div>
						</div>

						<div class="input-login-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label>{{$t('subscription.country')}}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-globe /></div>
								<multiselect 
									v-model="user.country"
									:options="optionsCountries"
									:allow-empty="true"
									:show-labels="false"
									:multiple="false" 
									:close-on-select="true"
									:placeholder="$t('subscription.choose_country')"
									track-by="iso" 
									label="name"
									:maxHeight="250"
									openDirection="top"
									>
									<template slot="noResult">{{ $t('general.no_result') }}</template>
									<template slot="noOptions">{{ $t('general.empty_list') }}</template>
								</multiselect>
							</div>
						</div>
					</div>
					<div class="submit-form">
						<button id="buttonNextStep" class="btn-tbf blue center" @click="goNextStep">
							<div class="loader"></div>
							<div class="text">{{$t('register.next_step')}}</div>
						</button>
					</div>

					<div class="existing-account">
						<div>
							{{$t('register.account_ok')}} <router-link :to="{path: '/login' }" class="to-login">{{$t('register.login')}}</router-link>
						</div>
					</div>
				</div>
			</div>
			
		</div>

	</div>
</template>


<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../../assets/animations/tbfdigital_logo.json';
	import IconClose from '../Icons/Close'
	import IconPeople from "../Icons/User"
	import IconEmail from '../Icons/Email'
	import IconPhone from '../Icons/Phone'
	import IconPassword from '../Icons/Password'
	import IconGlobe from '../Icons/Globe'

	import { required, minLength, email } from 'vuelidate/lib/validators'
	const isPhone = (value) => /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value)
	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				error: false,
				errorData: [],
				mail_exists: false,
				optionsCountries: []
			}
		},
		props: {
			user: Object
		},
		components: {
			IconClose,
			IconPeople,
			IconEmail,
			IconPhone,
			IconPassword,
			IconGlobe,
			Lottie
		},
		validations: {
			user: {
				first_name: {required},
				last_name: {required},
				email: {required, email},
				phone: {
					phoneValid:isPhone
				},
				password: {required,minLength: minLength(6)},
			}
		},
		async mounted() {
			await this.getCountries()
			this.user.has_company = 1;
			this.user.referral_uuid = this.$route.query.referral ? this.$route.query.referral : false
			this.logoAnim();
		},
		methods: {
			async getCountries(){
				await axios.get('/countries')
				.then(({data}) => {
					for (var key of Object.keys(data.data)){
						this.optionsCountries.push({iso: key, name: data.data[key]})
					}
				})
			},
			goNextStep(e){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true
				
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				
				this.$v.$touch()
				if(this.$v.$invalid){
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('register.next_step')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}else{
					var checkEmail = false
					axios.get('/email-validation', { params: {email: this.user.email}})
					.then(({data}) => {
						if(!data.data.is_valid){
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.error')
								btnSubmit.classList.add('error')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('error')
									this.errorData['email'] = true
									this.mail_exists = true
									btnSubmitText.innerHTML = this.$t('register.next_step')
								}, 1000)
							}, 300)
						}else{
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.success')
								btnSubmit.classList.add('completed')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('completed')
									btnSubmitText.innerHTML = this.$t('register.next_step')
									// this.$emit("next_step", {user: this.user, next_step: 2});
									this.$emit("next_step", {user: this.user, next_step: 3});
								}, 1000)
							}, 300)
						}
					}).catch(error => {
						if(error.response) {
							if(error.response.status == 500) {
								alert(this.$t('error.500'))
							}
						}
					})
					.finally(() => {
						setTimeout(()=>{
							btnSubmit.disabled = false
						}, 1500)
					})
				}

			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			},
			toLogin() {
				this.$router.push("/login")
			}
		}
	}
</script>
<style lang="scss">
	input.input-text.phone::-webkit-outer-spin-button,
	input.input-text.phone::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	
</style>